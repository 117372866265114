import React, { Suspense } from "react";
import Routes from "./Routes";

function App() {
  return (
    <Suspense
      fallback={<div>{/* TODO: put a loader here, fancy one plz */}</div>}
    >
      <Routes />
    </Suspense>
  );
}

export default App;
