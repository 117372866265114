import styled from "styled-components";
import { flexUnit } from "../../assets/mixins";
const HomePageDisplay = styled.div`
  h2 {
    ${flexUnit(5, 32, 60, "vw", "font-size")}
  }
  .container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .left {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .img-box {
      margin-bottom: 5vh;
      display: flex;
      justify-content: center;
    }
    .img-cont {
      position: relative;
      :hover {
        .icon {
          -webkit-filter: grayscale(0);
          filter: none;
        }
        .rotating {
          animation: rotating 0.5s linear infinite;
        }
      }
    }
    .name-box {
      margin-left: 40px;
      .hi {
        ${flexUnit(5, 16, 25, "vw", "font-size")};
        font-family: "Rubik Mono One", sans-serif;
      }
      h1 {
        ${flexUnit(5, 32, 60, "vw", "font-size")};
        font-family: "Rubik Mono One", sans-serif;
      }
    }
  }
  .right {
    width: 100%;
    min-height: 100vh;
    .titles {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      min-height: 100vh;
      div {
      }
      h2 {
        ${flexUnit(5, 32, 60, "vw", "font-size")};
        font-family: "Rubik Mono One", sans-serif;
        text-transform: uppercase;
        font-weight: 100;
        -webkit-text-stroke: 1px #fff;
        color: transparent;
        margin: 5vh;
      }
      p {
        color: #fff;
      }
    }
  }
`;
export default HomePageDisplay;
