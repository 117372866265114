import React from 'react';
import './WorkPage.scss';
import WorkPageDisplay from './WorkPage.styled';

export default function WorkPage() {
	return (
		<WorkPageDisplay>
			WorkPage_Component
		</WorkPageDisplay>
	)
}

