import React from "react";
import "./HomePage.scss";
import HomePageDisplay from "./HomePage.styled";
export default function HomePage() {
  const lvls = skillLvls.map((i, idx) => {
    return (
      <div className={`level lvl${idx + 1}`} key={`gskill-${idx}`}>
        <div className="circ">
          {i.map((j, idy) => {
            return (
              <div className="skill" key={`skill-${idy}`}>
                <div className={`icon ${j}`}></div>
              </div>
            );
          })}
        </div>
      </div>
    );
  });
  return (
    <HomePageDisplay>
      <div className="container">
        <div className="left white-bg">
          <div className="img-box">
            <div className="img-cont">
              <div className="myimg"></div>
              <div className="lvls rotating">{lvls}</div>
            </div>
          </div>
          <div className="name-box">
            <div className="hi">Hello there, I'm</div>
            <h1>Ahmed Abdelsalam</h1>
          </div>
        </div>
        <div className="right black-bg">
          <div className="titles">
            <div>
              <h2>Developer,</h2>
              {/* <p>I make websites</p> */}
              <h2>Blogger,</h2>
              {/* <p>I write some articles</p> */}
              <h2>Trainer &</h2>
              {/* <p>I love teaching others</p> */}
              <h2>Dreamer</h2>
              {/* <p>I have a dream</p> */}
            </div>
          </div>
        </div>
      </div>{" "}
    </HomePageDisplay>
  );
}
const skillLvls = [
  ["html", "css", "js"],
  ["react", "angular", "chrome"],
  ["nodejs", "mongodb", "linux"]
];
